.site-layout-content {
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

#components-layout-demo-top .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
}

.logo-style {
    color: white;
}

.ant-btn-group {
    display: block !important;
}

.custom-array-input {
    margin: 0 0 24px 0;
}

.custom-array-input:last-child {
    margin: 0;
}

.custom-array-input div {
    display: flex;
    width: 100%;
    margin-bottom: 0;
}

.custom-array-input div .ant-form-item {
    margin-bottom: 0;
}

.textarea-style textarea {
    resize: none;
    min-height: 40px;
}

.add-modal-style {
    z-index: 999;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.ant-modal-body {
    padding-left: 70px !important;
    padding-right: 70px !important;
    text-align: center;
}

.modal-content-style {
    text-align: left;
}

.submit-button-block {
    text-align: center;
}

.ant-table-tbody {
    height: 670px;
}

.ant-form-item-children-icon {
    visibility: hidden !important;
}

.submit-button-block button {
    margin: 0 15px;
}

.logout-button-style {
    margin-top: 16px;
}

.audio-style {
    position: absolute;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    animation: slide-in 1s forwards;
    -webkit-animation: slide-in 1s forwards;
}

.ant-table-cell {
    min-width: 150px;
}

@keyframes slide-in {
    100% { transform: translateX(-12%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(-12%); }
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #001529;
}

::-webkit-scrollbar-thumb:hover {
    background: #595959;
}

.highlight-class {
    background: #FFD63F;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    text-align: left !important;
}